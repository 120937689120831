<template>
  <div class="pt-3">
    <h4>பரவலாக்கப்பட்ட உலகத்தமிழர் மின் ஆவணக் காப்பகம்</h4>
    <h5>Decentralizing digital assets of Tamil language</h5>
    <p>
      எம் செம்மொழி காக்க இன்றே இந்த செயற்திட்டத்தில்
      <a href="https://app.tamilcrypto.org" target="_blank">இணையுங்கள்</a>
      <br />
    </p>
  </div>

  <!--Filter-->
  <div class="card shadow mb-3">
    <div class="card-body">
      <div class="form-group">
        <label for="peerId" class="form-label">
          Select Main Category
        </label>
        <select
          class="form-control form-select"
          v-model="mainCategory"
          id="dropMainCat"
          @change="changeMainCat"
        ></select>
      </div>

      <div class="form-group">
        <label for="PeerId" class="form-label">
          Select Sub Classification
        </label>
        <select
          class="form-control form-select"
          v-model="subcategory"
          id="dropSubCat"
        >
          <option value="0" hidden>Choose...</option>
        </select>
      </div>

      <div class="form- pt-3">
        <button
          class="btn btn-block btn-dark"
          type="button"
          @click="mapViwer(1), filterValidateAssetes()"
        >
          <i class="fas fa-save"></i> &nbsp; Filter
        </button>

        <button class="btn btn-block btn-warning" type="button" @click="reset">
          <i class="fas fa-undo"></i> &nbsp; Reset
        </button>
      </div>
    </div>
  </div>
  <!--Filter-->

  <!--Map start-->
  <div class="mb-3">
    <div id="map"></div>
  </div>
  <!--Map end-->

  <!--Validator Table-->
  <div class="card-body shadow mb-3 table-responsive">
    <h3>Approved Assests</h3>
    <hr />
    <table class="table table-hovarable" id="tblValidateAssetes">
      <thead>
        <tr>
          <th>Validate Date</th>
          <th class="text-center">Node</th>
          <th>File ID</th>
          <th>Category</th>
          <th>Sub Category</th>
          <th>Description</th>
          <th>Reason</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="asset in validateAssetes" :key="asset.id">
          <td>{{ asset.registerDate }}</td>
          <td class="text-center">
            <i class="fas fa-tv"></i>
            <br />
            {{ asset.assest.pubName }}
            <br />
            {{ asset.assest.country }}
          </td>
          <td>
            <a
              :href="
                'https://s3.ap-southeast-1.amazonaws.com/static.tamiltoken.org/' +
                  asset.cid +
                  '.pdf'
              "
              target="_blank"
            >
              {{ asset.cid }}
            </a>
          </td>
          <td>{{ getCategoryName(asset.assest.catId) }}</td>
          <td>
            {{ getSubCategoryName(asset.assest.catId, asset.assest.subCatId) }}
          </td>
          <td>{{ asset.assest.description }}</td>
          <td>{{ asset.reason }}</td>
          <th>
            <button class="btn btn-danger btn-block" @click="reportAssest">
              <i class="fas fa-exclamation-triangle"></i>
              Report
            </button>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
  <!--Validator Table-->
</template>

<script>
import axios from "axios";
import countryCoords from "../../country-geo-coords.js";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "archive",
  data() {
    return {
      subcategory: 0,
      mainCategory: 0,
      assestData: [],
      markers: [],
      map: "",
      validateAllAssetes: [],
      validateAssetes: [],
      ipfsData: [],
    };
  },
  mounted() {
    this.map = L.map("map").setView([7.873054, 80.771797], 3);

    this.getCategoryData();
    this.addMainCat();

    this.getAssets();
    this.getValidateAssetes();
  },
  methods: {
    getAssets() {
      let loader = this.$loading.show();
      axios
        .get("https://static.tamiltoken.org/assets.json")
        .then((response) => {
          this.assestData = response.data;
        })
        .finally(() => {
          loader.hide();
          this.mapViwer(0);
        });
    },
    mapViwer(clear) {
      let loader = this.$loading.show();

      //Config Map
      var map = this.map;

      map.setView([7.873054, 80.771797], 3);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      var LeafIcon = L.Icon.extend({
        options: {
          iconSize: [20, 20],
          iconAnchor: [14, 14],
          popupAnchor: [-3, -76],
        },
      });

      var tamilCryptoLogo = new LeafIcon({
        iconUrl: "https://tamiltoken.org/img/logo.98173be7.png",
      });
      //Config Map

      //Reset Map
      var markers = this.markers;
      if (clear == 1) {
        markers.forEach(function(marker) {
          map.removeLayer(marker);
        });
        markers.length = 0;
      }
      //Reset Map

      //Get Country Data
      var assestData = this.assestData;
      var catId = this.mainCategory;
      var subCatId = this.subcategory;

      assestData = filterData(catId, subCatId, assestData);
      var countryList = [];

      countryList = [...new Set(assestData.map((item) => item.country))];

      countryList.forEach((item) => {
        if (item in countryCoords) {
          var coords = countryCoords[item];

          var lat = coords.split("|")[0];
          var long = coords.split("|")[1];

          var marker = L.marker([lat, long], { icon: tamilCryptoLogo })
            .bindPopup(item)
            .addTo(map);

          this.markers.push(marker);
        }
      });
      //Get Country Data

      loader.hide();
    },
    getValidateAssetes() {
      let loader = this.$loading.show();

      axios
        .get(
          "https://s3.ap-southeast-1.amazonaws.com/static.tamiltoken.org/validation.json"
        )
        .then((response) => {
          const assests = approvedFilterData(response.data);
          this.validateAllAssetes = assests;
          console.log(this.validateAllAssetes);
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => {
          loader.hide();
          this.filterValidateAssetes();
        });
    },
    filterValidateAssetes() {
      let loader = this.$loading.show();

      var validateAllAssetes = this.validateAllAssetes;
      var catId = this.mainCategory;
      var subCatId = this.subcategory;

      var validateAssetes = validateFilterData(
        catId,
        subCatId,
        validateAllAssetes
      );

      this.validateAssetes = validateAssetes;
      loader.hide();
    },
    getCategoryData() {
      $.getJSON("ipfs.json", function(json) {
        localStorage.setItem("ipfsData", JSON.stringify(json));
      });
    },
    addMainCat() {
      const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
      const arrayLength = ipfsData.length;
      var ipfsOptions = "<option value='0' hidden>Choose...</option>";
      for (let i = 0; i < arrayLength; i++) {
        var optionData = ipfsData[i]["text"].split("|")[0];
        var optionValue = ipfsData[i]["id"];
        ipfsOptions +=
          "<option value=" + optionValue + ">" + optionData + "</option>";
      }
      $("#dropMainCat").html(ipfsOptions);
    },
    changeMainCat() {
      var catId = $("#dropMainCat").val();
      const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
      const arrayLength = ipfsData.length;
      var ipfsSubOptions = "<option value='0' hidden>Choose...</option>";
      for (let i = 0; i < arrayLength; i++) {
        var mainCatId = ipfsData[i]["id"];

        if (mainCatId == catId) {
          const ipfsSubCat = ipfsData[i]["nodes"];
          const subArrayLength = ipfsSubCat.length;
          for (let i = 1; i < subArrayLength; i++) {
            var subOptionData = ipfsSubCat[i]["text"];
            var subOptionValue = ipfsSubCat[i]["id"];
            ipfsSubOptions +=
              "<option value=" +
              subOptionValue +
              ">" +
              subOptionData +
              "</option>";
          }
        }
      }
      $("#dropSubCat").html(ipfsSubOptions);
    },
    getCategoryName(catId) {
      const jsonData = JSON.parse(localStorage.getItem("ipfsData"));
      let mainCategoryText = "";

      for (let mainCategory of jsonData) {
        if (mainCategory.id == catId) {
          mainCategoryText = mainCategory.text;
          mainCategoryText = mainCategoryText.split("|")[0];
          break;
        }
      }

      return mainCategoryText;
    },
    getSubCategoryName(catId, subCatId) {
      const jsonData = JSON.parse(localStorage.getItem("ipfsData"));

      let subCategoryText = "";
      for (let mainCategory of jsonData) {
        if (mainCategory.id == catId) {
          for (let subCategory of mainCategory.nodes) {
            if (subCategory.id == subCatId) {
              subCategoryText = subCategory.text;
              break;
            }
          }
          break;
        }
      }

      return subCategoryText;
    },
    reset() {
      this.mainCategory = 0;
      this.subcategory = 0;

      this.mapViwer(1);
      this.filterValidateAssetes();
    },
    reportAssest() {
      Swal.fire({
        title: "Copyright Compliance Notice",
        text:
          "We respect copyright laws and are committed to removing any content that infringes upon the rights of authors. If you believe that your copyrighted work has been uploaded to our platform without authorization, please contact us at support@tamilcrypto.org for prompt removal.",
        icon: "warning",
      });
    },
  },
};

//Filter Array
function filterData(catId, subCatId, data) {
  return data.filter((item) => {
    if (catId == 0 && subCatId == 0) {
      return true; // Return all arrays
    } else if (catId != 0 && subCatId == 0) {
      return item.catId == catId; // Filter with catId only
    } else if (catId == 0 && subCatId != 0) {
      return true; // Return all arrays
    } else {
      return item.catId == catId && item.subCatId == subCatId; // Filter with both catId and subCatId
    }
  });
}
//Filter Array

//Approved Filter Array
function approvedFilterData(data) {
  return data.filter((item) => {
    if (item.status == "Approve") {
      return true;
    }
  });
}
//Approved Filter Array

//Validate Filter Array
function validateFilterData(catId, subCatId, data) {
  return data.filter((item) => {
    if (catId == 0 && subCatId == 0) {
      return true; // Return all arrays
    } else if (catId != 0 && subCatId == 0) {
      return item.assest.catId == catId; // Filter with catId only
    } else if (catId == 0 && subCatId != 0) {
      return true; // Return all arrays
    } else {
      return item.assest.catId == catId && item.assest.subCatId == subCatId; // Filter with both catId and subCatId
    }
  });
}
//Validate Filter Array
</script>

<style scoped>
html,
body {
  height: 200px;
}
.leaflet-container {
  height: 400px;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
</style>
